import styled from 'styled-components';
import { Divider } from 'antd';
import { Link} from 'react-router-dom';

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
  }

  export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`
  };

export const CardContainer=styled.div`
    margin-bottom: 1rem;
    margin-top: 1rem; 
`
export const StyledButton=styled.button`
    /* Adapt the colors based on primary prop */
    background: ${props => props.primary ? "#bf2100" : "#fff"};
    color: ${props => props.primary ? "#fff" : "#bf2100"};
    font-size: 1em;
    border: 2px solid #bf2100;
    &:hover{
        background: ${props => props.primary ? "#d12302" : "#bf2100"};
        color: ${props => props.primary ? "#fff" : "#fff"};
        border: 2px solid #bf2100;
    }
`

export const StyledLink=styled(Link)`
    display: inline-block;
    background: ${props => props.primary ? "#bf2100" : "#fff"};
    color: ${props => props.primary ? "#fff" : "#bf2100"};
    font-size: ${props => props.font1em ? '1em' : "1.15em"};
    border: 2px solid #bf2100;
    border-radius: 0.5em;
    padding: 10px;
    width: 15em;
    /* font-size: 1.15em; */
    margin: 0.5em;
    &:focus {
        outline-width: 0px;
    };
    &:hover{
        background: ${props => props.primary ? "#d12302" : "#bf2100"};
        color: ${props => props.primary ? "#fff" : "#fff"};
        border: 2px solid #bf2100;
    }
`

// export const StyledTertiaryBtn=styled.button`
//     display: inline-block;
//     /* background: #bf2100; */
//     color: #bf2100;
//     font-size: 1em;
//     border-bottom: 2px solid #bf2100;
//     border-radius: 0px;
//     /* padding: 10px;
//     width: 15em;
//     font-size: 1.15em;
//     margin: 0.5em; */
//     &:focus {
//         outline-width: 0px;
//     };
//     &:hover{
//         color: #bf2100;
//     }
// `

export const StyledPrimaryActionButton=styled(StyledButton)`
    background: ${props => {
			if (props.disabled) {
				return "#e3e4eb"
			}
			return props.primary ? "#bf2100" : "#fff"
		}
	};
	
    color: ${props => {
		if(props.disabled) {
			return "#bbbccc";
		}
		return props.primary ? "#fff" : "#bf2100";
	}};
    font-size: 1.05em !important;
	font-weight: 700;
	letter-spacing: 1px;
    border: ${props => {
		if (props.disabled) {
			return 'none'
		}
		return props.primary ? "none" : '2px solid #bf2100'
	}};
    width:100%;
    height:3em;
    cursor: ${props => props.disabled ? 'not-allowed' : "pointer"};
    border-radius: 1em;
	margin: 3em 0 0;
    &:focus {
        outline-width: 0px;
        box-shadow: none;
    };
    &:hover{
        background: ${props => {
			if (props.disabled) {
				return "#e3e4eb"
			}
			return props.primary ? "#bf2100" : "#fff"
			}
		};
		border: ${props => {
			if (props.disabled) {
				return 'none'
			}
			return props.primary ? "none" : '2px solid #bf2100'
		}};
		color: ${props => {
			if(props.disabled) {
				return "#bbbccc";
			}
			return props.primary ? "#fff" : "#bf2100";
		}};
		background-image: ${props => {
			if (!props.disabled) {
				return 'linear-gradient(to right, #bf2100 0%, #EA384D  51%, #bf2100  100%)'
			}
		}};
		transition: 0.5s;
		box-shadow: 0 0 20px #eee;
		background-size: 200% auto;
		background-position: right center;
    }
`

export const StyledImageContainer=styled.div`
    position: relative;
    text-align: center;
    color: white;
`

export const StyledVerifiedContainer=styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
`

export const StyledRatingContainer=styled.div`
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: 8px;
    right: 16px;
    padding:0px 10px;
    border-radius: 40px;
`

export const StyledStepContainer=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const StyledStepDivider=styled(Divider)`
    /* background: ${props => props.active ?'#bf2100':'gray'}; */
    background: ${props => props.color};
    height:${props => props.active ? '3em' : '1.8em'};
    padding:1px; 
    border-radius:10px;
`

export const StyledStep=styled.div`
    /* color: ${props => props.active ? "#bf2100" : "#000"}; */
    color: ${props => props.color};
    font-size: ${props => props.active ? '1.20em' : '.8em'};
    align-items: center;
    align-content: center;
    :hover{
        cursor:${props => props.pointer}
    }
`

export const StyledIconContainer=styled.div`
    margin-top: 2px;
    display: flex;
    justify-content: center;
`

export const StyledLabel=styled.label`
    font-weight: 700;
    padding: 2px 0px;
    margin: 0;
	display: flex;
`

export const StyledImagePreviewContainer=styled.div`
    width:100%;
    height: 4em;
    align-items: center;
    border:1px dotted #bf2100;
`

export const StyledImagePreviewList=styled.img`
    float:left;
    margin:0.25em;
    width:3.5em;
    height:3.5em;
`

export const StyledImageNameInPreviewList=styled.label`
    position: relative;
    text-align: start;
    margin:0.8em;
    float:left;
    align-content: center;
    font-size: 1.2em;
	max-width: 50%;
`

export const StyledTrashIconContainer=styled.div`
    width:3em;
    height:3em;
    float:right;
    margin:0.5em;
`

export const StyledCarouselButtons=styled.div`
    /* backdrop-filter: blur(10px); */
    /* background: 'rgba(191, 33, 0, 0.1)' */
    background-color: rgba(191, 33, 0, 0.4);
    position: absolute;
    margin:10px;
    border-radius: 40px;
    :hover{
        background-color: rgba(191, 33, 0, 0.6);
    }
`

export const StyledDivider=styled.hr`
    background-color:${props => props.color ? props.color : "#bf2100"};
    height: 1.5px;
`

export const StyledTertiaryBtn = styled.button`
    background: #fff;
    color: #000;
    font-weight: 700;
    font-size: 12px;
    font-family: Verdana;
    border: none;
    position: relative;
    padding: 0px 20px;
    margin: 0 8px 5px;
    text-align: center;
    letter-spacing: 0.13em;
    /* text-transform: uppercase; */
    height: 2.5em;
    &:focus {
    outline-width: 0px; 
    }
    &::before{
        content: "";
        position: absolute;
        left: 20%;
        right: 20%;
        bottom: 0;
        border-bottom: 3px solid #bf2100;
    }
    &:hover::before {
        transform: scaleX(1.8) scaleY(1);
    }
`

export const StyledNavLink = styled(Link)`
    color: #000 !important;
    font-weight: 700;
    font-size: 13px;
    font-family: Verdana;
    position: relative;
    margin: 0 8px 5px;
    padding-bottom: 10px;
    text-align: center;
    letter-spacing: 0.13em;
    height: 2.5em;
    &:hover {
        color: #676363 !important;
    }
`

export const StyledCloseLink=styled(Link)`
    color: #676363;
	&:hover {
        color: #000000 !important;
    }
`