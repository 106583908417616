import React, {useEffect, useState} from 'react'
import { SearchBarComponent, 
    StyledInput, StyledSearchButton, 
    StyledSelect, SearchBarDiv, StyledInputDiv, 
    StyledClearButton } from './styledComponents/searchBarStyledComponents';
import SearchIcon from './svgComponents/SearchIcon';
import { connect } from 'react-redux';
import { getCities, searchFlat } from '../actions';
import { useNavigate } from 'react-router-dom';

const SearchBar = ({getAllCities, spanFull, cities, search}) => {

    // const [availableFlats, setAvailableFlats] = useState(false);
    const [addressSearch, setAddressSearch] = useState('');
    const [citySearch, setCitySearch] = useState('');
    // const [clearVisible, setClearVisible] = useState(false);
    // const [navigateToResult, setNavigateToResult] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        getAllCities()
    }, []);

    const resetName = (event) =>{
        setAddressSearch('')
    }

    const handleChange = (event) => {
        let fieldName = event.target.name
        let value = event.target.value

        switch(fieldName) {
            case "citySearch": 
                if (value !== "") {
                    setCitySearch(value)
                }else{
                    setCitySearch('')
                }
                break; 
            case "addressSearch":
                if (value !== "") {
                    setAddressSearch(value)
                }else{
                    setAddressSearch('')
                }
                break;
            default:
                break;
        }
    }

    const getFlats = (event) => {
        if (event.key === "Enter" || event.type === "click") {
            // const {onFlatSearch} = this.props;
            if (addressSearch !== "") {
                sessionStorage.setItem("address", addressSearch);
                navigate(`/search-result?search=${search}&city=${citySearch}&area=${addressSearch}`)
            }
        }
    }

    return (
        <SearchBarComponent spanFull={spanFull ? true : false}>
                <SearchBarDiv className={spanFull ? 'w-100 mt-md-5 mt-4' : 'mt-md-5 mt-4'}>
                    <StyledSelect 
                            name="citySearch"
                            onChange={handleChange}
                            id="citySearch"
                    >
                        <option value="">Select City</option>
                    {
                        cities.map((city, index) =>
                            <option key={index} value={city}>{city}</option>
                        )
                    }
                    </StyledSelect>
                    <StyledInputDiv>
                        <StyledInput 
                            id="addressSearch"
                            name="addressSearch"
                            type="search" 
                            placeholder="Search Location..." 
                            onChange={handleChange}
                            value={addressSearch || ''}
                            onKeyPress={e => {
                                getFlats(e);
                            }}
                        />
                        {addressSearch.length>0 ? 
                        <StyledClearButton onClick={resetName}>
                            &times;
                        </StyledClearButton>
                        :null}
                    </StyledInputDiv>
                    <StyledSearchButton
                        name="searchButton"
                        onClick={getFlats}
                        id="search-button" 
                        type="button"
                    >
                      {/* <MediaQuery minWidth={500}>Search</MediaQuery> */}
                      {/* <MediaQuery maxWidth={500}> */}
                            <SearchIcon></SearchIcon> 
                      {/* </MediaQuery>    */}
                    </StyledSearchButton>
                </SearchBarDiv>
            </SearchBarComponent>
    )
} 

// export default SearchBar;

function mapStateToProps(state) {
    const {flatSearch}=state
    return flatSearch
  }
  
const mapDispatchToProps = dispatch => {
return {
    getAllCities:_=>dispatch(getCities()),
    onFlatSearch: (flatData) =>dispatch(searchFlat(flatData))
}
}
  
export default connect(mapStateToProps,mapDispatchToProps)(SearchBar);



  
